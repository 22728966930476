



































import {defineComponent, PropType} from '@vue/composition-api';
import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import TableInput from '@/tasks/components/inputs/TableInput.vue';
import {VTextField} from 'vuetify/lib/components';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default defineComponent({
  name: 'uOttawa1321Lab2Question7',
  components: {TableInput, STextarea, CalculationInput, VTextField},
  mixins: [DynamicQuestionMixin()],
  props: {
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
    return {
      inputs: {
        aiQuestionResponse: null,
        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
      questions: [
        {
          en: 'A student dissolves 95 mg of a crude product in 3.5 mL (the minimum required) of methanol at 25°C. She cools the solution in an ice bath and obtains crystals. The crystals are recovered by filtration and rinsed with 0.5 mL of ice-cold methanol. After drying, the weight of the crystals is 5 mg. Why was the recovery so poor? What could she do to improve the process?',
          fr: 'Un étudiant dissout 95 mg de produit brut dans 3,5 mL (la quantité minimale nécessaire) de méthanol à 25°C. La solution est refroidie dans un bain de glace et l’étudiant obtient des cristaux. Les cristaux sont ensuite filtrés et lavés avec 0,5 mL d’éthanol froid. Une fois séchés, ces derniers pèsent 5 mg. Expliquez pourquoi le rendement obtenu est si faible et dites comment ce dernier aurait pu être amélioré.',
        },
      ],
    };
  },
  methods: {
    getQuestionText(question: any): string {
      return this.inputs.language === 'en' ? question.en : question.fr;
    },
  },
});
